import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageHeader, BannerLeft, BannerButton } from "../utils"
import img from "../images/bcg/fresh-veggies.jpg"
// import ContactForm from "../components/globals/ContactForm"

const CateringPage = () => (
  <Layout>
    <SEO title="Catering & Events" keywords={[`Catering Sylva, Dillsboro, and greater Jackson County NC`]} />
    <PageHeader img={img}>
      <BannerLeft title="Catering" subtitle="Make your event delicious">
        <hr />
        <p>
          A great event is a whole lot like a great meal: it’s fresh, it’s
          tasteful, and it happens at just the right time.
        </p>
      </BannerLeft>
    </PageHeader>

    <section id="catering">
      <div className="row px-0 py-5 justify-content-center">
        <div className="col-12 col-md-10 col-lg-9 px-0 py-4 mx-0">
          <h2>Stay in. Pig out.</h2>
          <p>
            Good food is always a good plan. So whether you're hosting a banquet
            or a ball, a wedding or a work-thing, or a get-together with the
            girls, adding Foragers Canteen to the guest list is as easy as clicking the button
            below and filling out the catering form. We can't wait to serve you!
          </p>
        </div>
      </div>
      
      <div className="row px-0 justify-content-center catering-form" >
        <BannerButton>
           <a href="https://foragerscanteen.tripleseat.com/party_request/26277">Get a Quote</a >
         </BannerButton>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </section>
  </Layout>
)

export default CateringPage
